import "core-js/modules/es.number.constructor.js";
import { useCssVars as _useCssVars } from 'vue';
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-19888982"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  key: 0,
  class: "video-thumbnail",
  style: {
    "border-radius": "5px"
  }
};
import { computed } from 'vue';
import { Play } from '@/assets/SVGs/AutoTuneIconLib';
export default {
  props: {
    videoThumbnail: {
      type: String,
      required: true
    },
    videoIndex: {
      type: Number,
      required: true
    }
  },
  emits: ['playVideo'],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;

    _useCssVars(function (_ctx) {
      return {
        "94d3f9c6": _unref(bgImage)
      };
    });
    /**
     * Computed property that returns the CSS formated value for the 'background-image' SCSS property in the 'video-thumbnail' class.
     * @returns {string}
     */


    var bgImage = computed(function () {
      return "url(".concat(props.videoThumbnail, ")");
    });
    return function (_ctx, _cache) {
      var _component_el_button = _resolveComponent("el-button");

      return __props.videoThumbnail ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_button, {
        size: "large",
        type: "primary",
        class: "extra-large black-base btn-override",
        circle: "",
        icon: _unref(Play),
        onClick: _cache[0] || (_cache[0] = function ($event) {
          return emit('playVideo', __props.videoIndex);
        })
      }, null, 8, ["icon"])])) : _createCommentVNode("", true);
    };
  }
};