import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-af66e9c4"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "size-page-section-header"
};
export default {
  props: {
    heading: {
      type: String,
      required: false
    },
    subheading: {
      type: String,
      required: false
    },
    tagline: {
      type: String,
      required: false
    }
  },
  setup: function setup(__props) {
    var props = __props;
    return function (_ctx, _cache) {
      var _component_el_row = _resolveComponent("el-row");

      var _component_el_col = _resolveComponent("el-col");

      return _openBlock(), _createBlock(_component_el_row, {
        align: "middle",
        justify: "center",
        class: "std-padding_section-bottom-lg",
        style: {
          paddingLeft: '12px',
          paddingRight: '12px'
        }
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_el_col, {
            xs: {
              span: 24
            },
            sm: {
              span: 24
            },
            md: {
              span: 12
            },
            lg: {
              span: 12
            },
            xl: {
              span: 12
            },
            class: "homepage-heading-wrapper"
          }, {
            default: _withCtx(function () {
              return [__props.heading ? (_openBlock(), _createBlock(_component_el_row, {
                key: 0,
                align: "middle",
                justify: "center",
                class: "std-padding_line-sm"
              }, {
                default: _withCtx(function () {
                  return [_createElementVNode("h1", _hoisted_1, _toDisplayString(__props.heading), 1)];
                }),
                _: 1
              })) : _createCommentVNode("", true), __props.subheading ? (_openBlock(), _createBlock(_component_el_row, {
                key: 1,
                align: "middle",
                justify: "center",
                class: "std-padding_line-lg"
              }, {
                default: _withCtx(function () {
                  return [_createElementVNode("h2", null, _toDisplayString(__props.subheading), 1)];
                }),
                _: 1
              })) : _createCommentVNode("", true), __props.tagline ? (_openBlock(), _createBlock(_component_el_row, {
                key: 2,
                align: "middle",
                justify: "center"
              }, {
                default: _withCtx(function () {
                  return [_createElementVNode("p", null, _toDisplayString(__props.tagline), 1)];
                }),
                _: 1
              })) : _createCommentVNode("", true)];
            }),
            _: 1
          })];
        }),
        _: 1
      });
    };
  }
};